import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const API_BASE_URL = "https://api.433200.xyz/api/";

const ApiDetailTemplate = ({
  title = "API 详情",
  description = "这是一个API的详细介绍。",
  url = "/endpoint",
  requestParams = [],
  requestExample = [],
  responseFormat = "JSON",
  responseExample = "",
}) => {
  // 复制到剪贴板函数
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        toast.success("Copy that!");
      },
      () => {
        toast.error("Warning ⚠️");
      }
    );
  };

  return (
    <div className="font-xwWenKai  min-h-screen flex flex-col items-center w-full">
      <div className="bg-pink-200/30 shadow-lg rounded-lg p-6 m-4 w-full max-w-lg">
        <h2 className="text-2xl text-violet-200 font-bold mb-4">{title}</h2>
        <p className="mb-4 text-white">{description}</p>

        <div className="mb-4">
          <h3 className="text-xl text-violet-200 font-bold mb-2">请求方式：</h3>
          <p className="bg-pink-200/30 text-white p-2 rounded">GET</p>
        </div>

        <div className="mb-4">
          <h3 className="text-xl text-violet-200 font-bold mb-2">请求地址：</h3>
          <div className="bg-pink-200/30  p-2 rounded flex items-center overflow-auto">
            <div className="flex-1 text-white break-words pr-2">{`${API_BASE_URL}${url}`}</div>
            <button
              onClick={() => copyToClipboard(`${API_BASE_URL}${url}`)}
              className="p-2 rounded hover:bg-slate-100/30 transition-colors duration-300 flex-shrink-0"
            >
              <FontAwesomeIcon icon={faClipboard} size="sm" color="white" />
            </button>
          </div>
        </div>

        {requestParams.length > 0 && (
          <div className="mb-4">
            <h3 className="text-xl text-violet-200 font-bold mb-2">
              请求参数：
            </h3>
            {requestParams.map((param, index) => (
              <div
                key={index}
                className="bg-pink-200/30 text-white p-2 rounded mb-2"
              >
                <div className="break-words">
                  {param.name}: {param.description}
                </div>
              </div>
            ))}
          </div>
        )}

        {requestExample.length > 0 && (
          <div className="mb-4">
            <h3 className="text-xl text-violet-200 font-bold mb-2">
              请求示例：
            </h3>
            {requestExample.map((example, index) => (
              <div
                key={index}
                className="bg-pink-200/30  p-2 rounded mb-2 flex items-center overflow-auto"
              >
                <div className="text-white flex-1 break-words pr-2 whitespace-pre-wrap">
                  {`${API_BASE_URL}${url}${example}`}
                </div>
                <button
                  onClick={() =>
                    copyToClipboard(`${API_BASE_URL}${url}${example}`)
                  }
                  className="p-2 rounded hover:bg-slate-100/30 transition-colors duration-300 flex-shrink-0"
                >
                  <FontAwesomeIcon icon={faClipboard} size="sm" color="white" />
                </button>
              </div>
            ))}
          </div>
        )}

        <div className="mb-4">
          <h3 className="text-xl text-violet-200 font-bold mb-2">返回格式：</h3>
          <p className="bg-pink-200/30 text-white p-2 rounded break-words">
            {responseFormat}
          </p>
        </div>

        <div className="mb-4">
          <h3 className="text-xl text-violet-200 font-bold mb-2">返回示例：</h3>
          <pre className="font-xwWenKai text-white bg-pink-200/30 p-2 rounded whitespace-pre-wrap break-words">
            {responseExample}
          </pre>
        </div>
      </div>
      <ToastContainer className="font-xwWenKai" />
    </div>
  );
};

export default ApiDetailTemplate;
